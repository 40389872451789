<template>
  <el-popover trigger="hover" :placement="mainPlacement"  width="200px" ref="mainPopover" :onShow="onshow" :onAfterLeave="onhide">
    <template #reference>
      <div class="organization" @mouseenter="loadOrganization" ref="inner">
      <slot name="reference" ></slot>
      </div>
    </template>
    <el-scrollbar max-height="400px"  ref="content">
      <div v-for="(order,index) in orders" :key="index">
      <el-popover  trigger="hover" :placement="sub_placement" @mouseenter="showItem=true" width="200px" v-if="order.approverType==1 && organizationMemberMap[order.approver]&& organizationMemberMap[order.approver].length>0">
        <template #reference>

            <div  class="approver"   >

              <user-icon :url="order.approverHeadImgUrl" :name="order.approverName" :uid="order.approverName" :sub-icon="order.operation?order.operation===1?'check':'close':''"></user-icon>
              <div style="display: flex;flex-direction: column;flex: 1;margin-left: 10px" class="name">
                <span class="truncate" style="font-size: 12px;font-weight: bold;display:inline-block" :title="order.approverName">{{order.approverName?order.approverName:order.approver}}</span>
                <span style="font-size: 12px;" v-if="order.approverType==1&& organizationMemberMap[order.approver]">{{organizationMemberMap[order.approver].length+" 成员"}}</span>
              </div>
              <div v-if="order.approverType==1"><i class="el-icon-caret-right"></i> </div>

            </div>

        </template>
        <el-scrollbar max-height="400px" @mouseenter="$refs.mainPopover.show()" @mouseleave="$refs.mainPopover.hide()">
          <div class="organization-member" v-for="(item,index2) in organizationMemberMap[order.approver]" :key="index2">

            <user-icon style="flex-shrink: 0" :url="item.headImgUrl" :name="item.userName" :uid="item.userName"></user-icon>

            <div style="margin-left: 10px" v-if="item" >
              <span :title="item.userName">{{item.userName}}</span>
            </div>

          </div>
        </el-scrollbar>
      </el-popover>
        <div v-else class="approver"  >

          <user-icon :url="order.approverHeadImgUrl" :name="order.approverName" :uid="order.approverName" :sub-icon="order.operation?order.operation===1?'check':'close':''"></user-icon>
          <div style="display: flex;flex-direction: column;flex: 1;margin-left: 10px" class="name">
            <span style="font-size: 12px;font-weight: bold" :title="order.approverName">{{order.approverName?order.approverName:order.approver}}</span>
            <span style="font-size: 12px;" v-if="order.approverType==1&& organizationMemberMap[order.approver]">{{organizationMemberMap[order.approver].length+" 成员"}}</span>
          </div>
          <div v-if="order.approverType==1"><i class="el-icon-caret-right"></i> </div>

        </div>
      </div>


    </el-scrollbar>
  </el-popover>
</template>

<script>
import {getOrganizationUser} from "../api/api";
import {getHashColor,getUserName} from "../util";
import UserIcon from "./UserIcon";

export default {
  name: "ApprovalMember",
  components: {UserIcon},
  props:["orders","main_placement","sub_placement"],
  data(){
    return{
      mainPlacement:this.main_placement,
      organizationMemberMap:{},
      getHashColor,
      showItemAtBottom:false,
      showMenuAtBottom:false,
      showMenu:false,
      showItem:false,
      getUserName

    }
  },
  mounted() {


  },
  beforeUnmount() {


  },
  methods:{

     loadOrganization(){
       // let item = document.getElementById('item'+this.idx);
       // if(item){
       //   return
       // }
       // this.showMenu = true;
        for (let index in this.orders) {
          let order = this.orders[index];
          if (order.approverType != 1) continue;
          if (this.organizationMemberMap[order.approver] == null) {
             getOrganizationUser(order.approver).then(res => {
              this.organizationMemberMap[order.approver] = res.data.data;
            }).catch(e => {
              console.error("getOrganizationMembers err==>" + e);
              this.$message.error("获取组织成员失败")
            })

          }
        }



        //  this.$nextTick(() => {
        //
        //
        //   let item = document.getElementById('item'+this.idx);
        //   document.body.append(item)
        //
        //   let top = this.$refs.organization.getBoundingClientRect().top + 28;
        //   let left = this.$refs.organization.getBoundingClientRect().left - 10;
        //
        //
        //   let rect = item.getBoundingClientRect();
        //   if (top + rect.height > document.body.clientHeight) {
        //     item.style.top = 'unset'
        //     this.showItemAtBottom = true
        //
        //   } else {
        //     item.style.top = top + 'px';
        //   }
        //   if (left + rect.width > document.body.clientWidth) {
        //     item.style.left = 'unset';
        //     item.style.right = '0px';
        //   } else {
        //     item.style.left = left + 'px';
        //   }
        // })
    },onshow(){
       this.mainPlacement=this.main_placement;
       this.$nextTick(()=>{
         let rect = this.$refs.content.$el.getBoundingClientRect();

         if(rect.y+rect.height+20>document.body.clientHeight){
           let innerRect = this.$refs.inner.getBoundingClientRect();
           if(innerRect.y-rect.height>0){
             this.mainPlacement='top'
           }
           else{
             this.mainPlacement='left'
           }
         }else{
           this.mainPlacement=this.main_placement;
         }
       })


    },
    onhide(){

       this.mainPlacement=this.main_placement;
    }

  }
}
</script>

<style scoped>

.organization{
  display: flex;
  align-items: center;
  flex-wrap: wrap;

}

.approver:hover .organization-menu{
  position: absolute;
  display: block;
  top: 0;

}
.organization-info{

}

.approver{
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 10px;

}

.organization-info{

  position: absolute;

  background: white;
  width: 200px;
  box-shadow:rgb(60 64 67 / 15%) 0 1px 3px 1px;
  z-index: 2005;


  top: 0;
  left: 0;


}
.approver:hover{
  background: #ddd;
}



 .organization-menu{
  /*display: none;*/
  background: white;

  box-shadow:rgb(60 64 67 / 15%) 0 1px 3px 1px;
  left: 200px;
   z-index: 99;


}

 .organization-member{
  background: white;

  padding: 5px 10px;
  display: flex;
  align-items: center;



}

.organization-member div{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.organization-member:hover{
  background: #dddddd;
}
.stage-order {
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
  color: white;
  overflow: hidden;
  position: relative;



  flex-shrink: 0;
  margin-right: -3px;
  border: solid 1px rgba(255, 255, 255, 0.3);
}

.name{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.stage-index {
  padding-right: 10px;
  border-right: solid 1px #ddd;
  margin-right: 10px;
  flex-shrink: 0;
}

.approval-stage {
  padding: 5px 10px;
}


.stage-badge {
  background: #55a532;
  position: absolute;
  left: -7px;
  top: -5px;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  line-height: 24px;
  text-align: center;
  box-shadow: rgb(60 64 67 / 15%) 1px 1px 3px 1px;
  color: white;
}

.tooltip{
  overflow: visible;
}

.tooltip .tooltiptext {
  visibility: hidden;

  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* 定位 */
  position: absolute;
  left: 0;
  z-index: 1;
}

/* 鼠标移动上去后显示提示框 */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
